<template>
  <div>
    <SubHeader :type="$t('preferences.title')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarUser />
        </div>
        <TransitionFade>
          <div class="md:col-span-4 h-auto" v-if="$store.state.Menu.isSettingsFormsOpen">
            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("preferences.project") }}</div>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="col-span-1">
                      <div class="my-4 md:my-0">
                        <label for="name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("preferences.language") }}</label>
                        <SelectLangs />
                      </div>
                    </div>
                    <div class="col-span-1">
                      <div class="my-4 md:my-0">
                        <label for="job_title" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("preferences.default") }}</label>
                        <Dropdown v-model="defaultProject" :options="projects" optionLabel="company" optionValue="id" dataKey="id" :placeholder="$t('select')" class="p-inputtext-sm w-full shadow-sm" />
                      </div>
                    </div>
                  </div>

                  <div class="py-2">
                    <div class="flex justify-start items-center">
                      <Button @click="updateDefault" class="p-button-rounded p-button-primary p-button-text" :label="$t('preferences.btnSave')" />
                      <TransitionFade>
                        <Loader v-if="loading" class="ml-3" :onlyLoader="true" />
                      </TransitionFade>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import TransitionFade from "@/components/TransitionFade"
import SidebarUser from "@/components/SidebarUser"
import axiosClient from "@/config/AxiosClient"
import SelectLangs from "@/components/SelectLangs.vue"
import Loader from "@/components/UI/Loader"

import Card from "primevue/card"
import Dropdown from "primevue/dropdown"
import Button from "primevue/button"

export default {
  name: "Preferences",
  props: ["project"],
  components: {
    SubHeader,
    TransitionFade,
    SidebarUser,
    SelectLangs,
    Loader,

    Card,
    Dropdown,
    Button
  },
  computed: {
    projects(){
      return this.$store.state.Projects.projects
    }
  },
  data: function () {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      TitleSection: "Personal data",
      isError: false,
      showLangSelect: false,
      lang: "",
      defaultProject: this.$store.state.Auth.user?.config?.project_default_id,
      loading: false,
      loadingProjects: false
    }
  },
  async created() {
    await this.getProjects()
    this.$store.dispatch("Menu/openSettingsForms")
    if (this.$store.state.Auth.user.config && this.$store.state.Auth.user?.config?.project_default_id) this.defaultProject = this.$store.state.Auth.user?.config?.project_default_id
  },
  methods: {
    closeMenuLangs: function () {
      this.$store.dispatch("Langs/closeMenuLangs")
    },
    async getProjects() {
      this.loadingProjects = true
      await this.$store.dispatch("Projects/getProjects", {})
      this.loadingProjects = false
    },
    async updateDefault() {
      this.loading = true
      let response = await axiosClient.put(`api/2.0/auth/profile/config/update`, {
        config: {
          project_default_id: this.defaultProject
        }
      })
      await this.$store.dispatch("Auth/getUser")
      if (response.status == 500) {
        this.$toast.error(this.$t("utils.toast.error"))
      }
      if (response.status == 422) {
        this.errorMessage = Object.keys(response.data.errors)
          .map(function (errorKey) {
            return response.data.errors[errorKey].join(",")
          })
          .join(",")
        this.error = true
      }
      if (response.status == 204) {
        this.$toast.success(this.$t("preferences.change"))
        this.loading = false
      } else {
        this.loading = false
        this.$toast.error(this.$t("utils.toast.error"))
      }
    }
  }
}
</script>

<style scoped>
.item {
  @apply w-full h-5 flex justify-start items-center;
}
.item .label {
  @apply hidden sm:block font-normal text-sm;
}
</style>
