<template>
  <ul class="flex md:flex-col gap-2">
    <router-link :to="{ name: 'Profile', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/user/profile` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="22" height="22" class="mx-auto">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      </div>
      <span class="ml-2">{{ t("profile.profile", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
    </router-link>
    <router-link :to="{ name: 'Preferences', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/user/profile/preferences` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="22" height="22" class="mx-auto">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
        </svg>
      </div>
      <span class="ml-2">{{ t("preferences.preferences", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
    </router-link>
  </ul>
</template>

<script>
import { useI18n } from "vue-i18n"

export default {
  name: "SidebarUser",
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  data: function () {
    return {
      location: location.href,
      isActive: null
    }
  },
  mounted() {
    const url = new URL(this.location)
    const path = url.pathname
    this.isActive = path
  }
}
</script>
